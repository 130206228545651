!(function (t) {
  var e = {};
  function n(o) {
    if (e[o]) return e[o].exports;
    var i = (e[o] = { i: o, l: !1, exports: {} });
    return t[o].call(i.exports, i, i.exports, n), (i.l = !0), i.exports;
  }
  (n.m = t),
    (n.c = e),
    (n.d = function (t, e, o) {
      n.o(t, e) || Object.defineProperty(t, e, { enumerable: !0, get: o });
    }),
    (n.r = function (t) {
      'undefined' != typeof Symbol &&
        Symbol.toStringTag &&
        Object.defineProperty(t, Symbol.toStringTag, { value: 'Module' }),
        Object.defineProperty(t, '__esModule', { value: !0 });
    }),
    (n.t = function (t, e) {
      if ((1 & e && (t = n(t)), 8 & e)) return t;
      if (4 & e && 'object' == typeof t && t && t.__esModule) return t;
      var o = Object.create(null);
      if (
        (n.r(o),
        Object.defineProperty(o, 'default', { enumerable: !0, value: t }),
        2 & e && 'string' != typeof t)
      )
        for (var i in t)
          n.d(
            o,
            i,
            function (e) {
              return t[e];
            }.bind(null, i)
          );
      return o;
    }),
    (n.n = function (t) {
      var e =
        t && t.__esModule
          ? function () {
              return t.default;
            }
          : function () {
              return t;
            };
      return n.d(e, 'a', e), e;
    }),
    (n.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }),
    (n.p = ''),
    n((n.s = 0));
})([
  function (t, e, n) {
    'use strict';
    n.r(e);
    const o = (t, e = 'log') => {
        'error' === e
          ? console && 'function' == typeof console.error && console.error(t)
          : console && 'function' == typeof console.info && console.info(t);
      },
      i = (t) => o(t, 'error'),
      s = () =>
        (navigator.getGamepads && 'function' == typeof navigator.getGamepads) ||
        (navigator.getGamepads &&
          'function' == typeof navigator.webkitGetGamepads) ||
        !1,
      a = 'Gamepad detected.',
      r = 'Gamepad disconnected.',
      c = 'Invalid property.',
      u = 'Invalid value. It must be a number between 0.00 and 1.00.',
      f = 'Button does not exist.',
      h = 'Unknown event name.',
      d = 'Your web browser does not support the Gamepad API.';
    var l = {
      init: function (t) {
        let e = {
          id: t.index,
          buttons: t.buttons.length,
          axes: Math.floor(t.axes.length / 2),
          axeValues: [],
          axeThreshold: [1],
          hapticActuator: null,
          vibrationMode: -1,
          vibration: !1,
          mapping: t.mapping,
          buttonActions: {},
          axesActions: {},
          pressed: {},
          set: function (t, e) {
            if (['axeThreshold'].indexOf(t) >= 0) {
              if ('axeThreshold' === t && (!parseFloat(e) || e < 0 || e > 1))
                return void i(u);
              this[t] = e;
            } else i(c);
          },
          vibrate: function (t = 0.75, e = 500) {
            if (this.hapticActuator)
              switch (this.vibrationMode) {
                case 0:
                  return this.hapticActuator.pulse(t, e);
                case 1:
                  return this.hapticActuator.playEffect('dual-rumble', {
                    duration: e,
                    strongMagnitude: t,
                    weakMagnitude: t,
                  });
              }
          },
          triggerDirectionalAction: function (t, e, n, o, i) {
            n && o % 2 === i
              ? (this.pressed[`${t}${e}`] ||
                  ((this.pressed[`${t}${e}`] = !0),
                  this.axesActions[e][t].before()),
                this.axesActions[e][t].action())
              : this.pressed[`${t}${e}`] &&
                o % 2 === i &&
                (delete this.pressed[`${t}${e}`],
                this.axesActions[e][t].after());
          },
          checkStatus: function () {
            let t = {};
            const e = navigator.getGamepads
              ? navigator.getGamepads()
              : navigator.webkitGetGamepads
              ? navigator.webkitGetGamepads()
              : [];
            if (e.length) {
              if (((t = e[this.id]), t.buttons))
                for (let e = 0; e < this.buttons; e++)
                  !0 === t.buttons[e].pressed
                    ? (this.pressed['button' + e] ||
                        ((this.pressed['button' + e] = !0),
                        this.buttonActions[e].before()),
                      this.buttonActions[e].action())
                    : this.pressed['button' + e] &&
                      (delete this.pressed['button' + e],
                      this.buttonActions[e].after());
              if (t.axes) {
                const e = t.axes.length % 2;
                for (let n = 0; n < 2 * this.axes; n++) {
                  const o = t.axes[n + e].toFixed(4),
                    i = Math.floor(n / 2);
                  (this.axeValues[i][n % 2] = o),
                    this.triggerDirectionalAction(
                      'right',
                      i,
                      o >= this.axeThreshold[0],
                      n,
                      0
                    ),
                    this.triggerDirectionalAction(
                      'left',
                      i,
                      o <= -this.axeThreshold[0],
                      n,
                      0
                    ),
                    this.triggerDirectionalAction(
                      'down',
                      i,
                      o >= this.axeThreshold[0],
                      n,
                      1
                    ),
                    this.triggerDirectionalAction(
                      'up',
                      i,
                      o <= -this.axeThreshold[0],
                      n,
                      1
                    );
                }
              }
            }
          },
          associateEvent: function (t, e, n) {
            if (t.match(/^button\d+$/)) {
              const o = parseInt(t.match(/^button(\d+)$/)[1]);
              o >= 0 && o < this.buttons
                ? (this.buttonActions[o][n] = e)
                : i(f);
            } else if ('start' === t) this.buttonActions[9][n] = e;
            else if ('select' === t) this.buttonActions[8][n] = e;
            else if ('r1' === t) this.buttonActions[5][n] = e;
            else if ('r2' === t) this.buttonActions[7][n] = e;
            else if ('l1' === t) this.buttonActions[4][n] = e;
            else if ('l2' === t) this.buttonActions[6][n] = e;
            else if ('power' === t)
              this.buttons >= 17 ? (this.buttonActions[16][n] = e) : i(f);
            else if (t.match(/^(up|down|left|right)(\d+)$/)) {
              const o = t.match(/^(up|down|left|right)(\d+)$/),
                s = o[1],
                a = parseInt(o[2]);
              a >= 0 && a < this.axes ? (this.axesActions[a][s][n] = e) : i(f);
            } else if (t.match(/^(up|down|left|right)$/)) {
              const o = t.match(/^(up|down|left|right)$/)[1];
              this.axesActions[0][o][n] = e;
            }
            return this;
          },
          on: function (t, e) {
            return this.associateEvent(t, e, 'action');
          },
          off: function (t) {
            return this.associateEvent(t, function () {}, 'action');
          },
          after: function (t, e) {
            return this.associateEvent(t, e, 'after');
          },
          before: function (t, e) {
            return this.associateEvent(t, e, 'before');
          },
        };
        for (let t = 0; t < e.buttons; t++)
          e.buttonActions[t] = {
            action: () => {},
            after: () => {},
            before: () => {},
          };
        for (let t = 0; t < e.axes; t++)
          (e.axesActions[t] = {
            down: { action: () => {}, after: () => {}, before: () => {} },
            left: { action: () => {}, after: () => {}, before: () => {} },
            right: { action: () => {}, after: () => {}, before: () => {} },
            up: { action: () => {}, after: () => {}, before: () => {} },
          }),
            (e.axeValues[t] = [0, 0]);
        return (
          t.hapticActuators
            ? 'function' == typeof t.hapticActuators.pulse
              ? ((e.hapticActuator = t.hapticActuators),
                (e.vibrationMode = 0),
                (e.vibration = !0))
              : t.hapticActuators[0] &&
                'function' == typeof t.hapticActuators[0].pulse &&
                ((e.hapticActuator = t.hapticActuators[0]),
                (e.vibrationMode = 0),
                (e.vibration = !0))
            : t.vibrationActuator &&
              'function' == typeof t.vibrationActuator.playEffect &&
              ((e.hapticActuator = t.vibrationActuator),
              (e.vibrationMode = 1),
              (e.vibration = !0)),
          e
        );
      },
    };
    const p = {
      gamepads: {},
      axeThreshold: [1],
      isReady: s(),
      onConnect: function () {},
      onDisconnect: function () {},
      onBeforeCycle: function () {},
      onAfterCycle: function () {},
      getGamepads: function () {
        return this.gamepads;
      },
      getGamepad: function (t) {
        return this.gamepads[t] ? this.gamepads[t] : null;
      },
      set: function (t, e) {
        if (['axeThreshold'].indexOf(t) >= 0) {
          if ('axeThreshold' === t && (!parseFloat(e) || e < 0 || e > 1))
            return void i(u);
          if (((this[t] = e), 'axeThreshold' === t)) {
            const t = this.getGamepads(),
              e = Object.keys(t);
            for (let n = 0; n < e.length; n++)
              t[e[n]].set('axeThreshold', this.axeThreshold);
          }
        } else i(c);
      },
      checkStatus: function () {
        const t =
            window.requestAnimationFrame || window.webkitRequestAnimationFrame,
          e = Object.keys(p.gamepads);
        p.onBeforeCycle();
        for (let t = 0; t < e.length; t++) p.gamepads[e[t]].checkStatus();
        p.onAfterCycle(), e.length > 0 && t(p.checkStatus);
      },
      init: function () {
        window.addEventListener('gamepadconnected', (t) => {
          const e = t.gamepad || t.detail.gamepad;
          if ((o(a), window.gamepads || (window.gamepads = {}), e)) {
            if (!window.gamepads[e.index]) {
              window.gamepads[e.index] = e;
              const t = l.init(e);
              t.set('axeThreshold', this.axeThreshold),
                (this.gamepads[t.id] = t),
                this.onConnect(this.gamepads[t.id]);
            }
            1 === Object.keys(this.gamepads).length && this.checkStatus();
          }
        }),
          window.addEventListener('gamepaddisconnected', (t) => {
            const e = t.gamepad || t.detail.gamepad;
            o(r),
              e &&
                (delete window.gamepads[e.index],
                delete this.gamepads[e.index],
                this.onDisconnect(e.index));
          });
      },
      on: function (t, e) {
        switch (t) {
          case 'connect':
            this.onConnect = e;
            break;
          case 'disconnect':
            this.onDisconnect = e;
            break;
          case 'beforeCycle':
          case 'beforecycle':
            this.onBeforeCycle = e;
            break;
          case 'afterCycle':
          case 'aftercycle':
            this.onAfterCycle = e;
            break;
          default:
            i(h);
        }
        return this;
      },
      off: function (t) {
        switch (t) {
          case 'connect':
            this.onConnect = function () {};
            break;
          case 'disconnect':
            this.onDisconnect = function () {};
            break;
          case 'beforeCycle':
          case 'beforecycle':
            this.onBeforeCycle = function () {};
            break;
          case 'afterCycle':
          case 'aftercycle':
            this.onAfterCycle = function () {};
            break;
          default:
            i(h);
        }
        return this;
      },
    };
    p.init();
    var b = p;
    s() ? (window.gameControl = b) : i(d);
  },
]);
